<script lang="ts" setup>
import { useNavDesktopItems } from '@/components/common/header/composables';
import {
	PrimeNavigation,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
const { items, navigationClick } = useNavDesktopItems();
</script>

<template>
	<nav class="desktop-nav">
		<PrimeNavigation
			v-for="link in items"
			:key="link.to"
			:data="link"
			color="primary"
			class="desktop-nav-link"
			@navigation-click="navigationClick($event)"
		/>
	</nav>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.desktop-nav {
	position: static;
	z-index: 1;
	display: none;
	margin: 0 auto;
	gap: 1.25rem;

	@include breakpoint(tablet) {
		position: absolute;
		left: 50%;
		display: flex;
		gap: 1.5rem;
		transform: translateX(-50%);
	}

	@include breakpoint(laptop) {
		gap: 4rem;
	}

	:deep(.p-nav-item) {
		position: relative;
		display: inline-flex;
		align-items: center;
		gap: 0.25rem;
		padding: 1.5rem 0;
		color: var(--p-grey-300);
		height: unset;
		border-radius: unset;

		.p-text {
			font-size: 0.875rem;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 0.125rem;
			opacity: 0;
			background-color: var(--p-secondary-base);
		}

    &:hover {
			color: var(--p-grey-300);
			background: transparent;
			&:before {
				opacity: 1;
			}
		}
	}

	:deep(.p-is-active) {
		.p-nav-item {
			color: var(--p-grey-300);
			background: transparent;
      &:hover:before,
			&:before {
				opacity: 1;
			}
		}
	}
}
</style>
