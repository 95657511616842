<script setup lang="ts">
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { onBeforeMount, ref } from 'vue';
import API from '@/api';
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';

const { isMobileApp } = useMobileApp();
const url = ref('');
const isLoading = ref(false);
const appStore = useAppStore();
const { trackingMaid } = storeToRefs(appStore);

onBeforeMount(async () => {
	if (isMobileApp) {
		isLoading.value = true;
		const { data } = await API.get('api/offerwall-events/adjoe');
		url.value = data.iframe;
		isLoading.value = false;
	}
});
</script>

<template>
	<PrimeLoaderSpinner v-if="isLoading || !url" />
	<div v-else class="games-wrapper">
		<iframe :src="`${url}&idfa=${trackingMaid}`" width="100%" height="100%" />
	</div>
</template>

<style scoped lang="scss">
.games-wrapper {
	width: 100%;
}
</style>
