import { useAuthStore } from '@/stores/auth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppStore } from '@/stores/app';
import { getRootDomain } from '@/utils/helpers';
import { UTILITY_ROUTE_NAME } from '@/constants/routes';
import { useUserStore } from '@/stores/user';
import { Echo } from '@/utils/echo';

export const utilityRoutes = [
	{
		path: '/logout',
		name: UTILITY_ROUTE_NAME.LOGOUT,
		beforeEnter: async () => {
			const authStore = useAuthStore();
			const appStore = useAppStore();
			const { isMobileApp } = useMobileApp();
			const userStore = useUserStore();
			const echo = Echo.getInstance();

			try {
				await authStore.logout();
			} catch (error) {
				console.error(error);
			}

			try {
				await authStore.removeToken();
			} catch (error) {
				console.error(error);
			}

			try {
				await appStore.resetTracking();
			} catch (error) {
				console.error(error);
			}

			userStore.$reset();
			authStore.$reset();
			echo.removeConnections();

			if (!isMobileApp) {
				window.location.assign(getRootDomain(true));
			}

			return isMobileApp ? '/' : '';
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: () => 'surveys',
	},
];
