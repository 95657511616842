<script setup lang="ts">
import PendingPayoutsFallback from '@/views/history/views/pendings/components/PendingPayoutsFallback.vue';
import { onMounted, ref } from 'vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { PrimeSkeleton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PendingTotal from '@/views/history/views/pendings/components/PendingTotal.vue';
import PendingPayoutItem from '@/views/history/views/pendings/components/PendingPayoutItem.vue';

const isLoading = ref(false);
const userStore = useUserStore();
const { pendingPayouts } = storeToRefs(userStore);

onMounted(async () => {
	isLoading.value = true;
	await userStore.fetchPendingPayouts();
	isLoading.value = false;
});
</script>

<template>
	<div>
		<template v-if="isLoading">
			<PrimeSkeleton v-for="i in 3" :key="i" class="pendings-skeleton" />
		</template>
		<div v-else-if="!isLoading && pendingPayouts.length">
			<PendingTotal />
			<PendingPayoutItem
				v-for="item in pendingPayouts"
				:key="item.pending_until"
				:item="item"
			/>
		</div>
		<PendingPayoutsFallback v-else />
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.rewards {
	padding: 1.5rem 0;
}

.pendings-skeleton {
	margin: 0.5rem 0;
	height: 3.5rem;
	width: 100%;
	@include breakpoint(mobile) {
		margin: 0.75rem 0;
	}
}
</style>
