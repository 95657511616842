<script lang="ts" setup>
import type { ClaimedReward, PendingItem, PendingPayouts } from '@/types';
import { AMOUNT, DATE, PENDING_UNTIL } from '@/constants/locales';
import RewardImage from '@/components/common/RewardImage.vue';
import ClaimRewardDetails from '@/views/history/views/rewards/components/ClaimRewardDetails.vue';
import { toDate, transformCurrency } from '@/utils/helpers';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ActionStatus from '@/components/ActionStatus.vue';
import { CLAIM_STATUS_CONTENT } from '@/views/history/views/rewards/components/statuses';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

const { item } = defineProps<{
	item: PendingItem;
}>();

const { currency } = storeToRefs(useUserStore());
const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';

const amountOfPending = computed(() => {
	return transformCurrency(+item.money_value! || 0, currency.value || 'USD', 2);
});
</script>

<template>
	<div class="pending-item-container">
		<div class="pending-item-content">
			<PrimeText weight="600">
				{{ item.offer_name }}
			</PrimeText>
			<PrimeText>
				{{ $t(PENDING_UNTIL) }} {{ toDate(item.pending_until, localization) }}
			</PrimeText>
		</div>
		<PrimeText weight="600">
			{{ amountOfPending }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.pending-item-container {
	display: flex;
	padding: 1rem 0.8125rem;
	margin-bottom: 0.75rem;
	background-color: var(--p-white);
	border-radius: 1rem;
	box-shadow: var(--5s-shadow-card);
	align-items: center;
	justify-content: space-between;
}
.pending-item-content {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
</style>
