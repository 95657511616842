import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels } from '@/enums';
import type { Notification } from '@/types';
import {
	HALF_PROGRESS_NOTIFICATION_MODAL,
	OFFER_REWARD_COMPLETE_MODAL,
	OFFER_REWARD_DELAY_MODAL,
	REFERRALS_NOTIFICATION_MODAL,
} from '@/constants/modals';

export const useAppNotificationListener = (): void => {
	const modalStorage = useModalStorage();
	const { notificationsList } = storeToRefs(useMessagesStore());
	const { userAuthToken } = storeToRefs(useAuthStore());

	const showNotificationsModal = ({ data, id }: Notification): void => {
		const requiredOptions = { id, channel: MessageChannels.NOTIFICATION };
		switch (data.event_name) {
			case 'one_time_intro_to_half_surveys':
				modalStorage.pushModal({
					name: HALF_PROGRESS_NOTIFICATION_MODAL,
					options: {
						...requiredOptions,
					},
				});
				break;
			case 'introduce_to_referrals':
				modalStorage.pushModal({
					name: REFERRALS_NOTIFICATION_MODAL,
					options: {
						...requiredOptions,
					},
				});
				break;
			case 'offerwall_transaction_delayed':
				modalStorage.pushModal({
					name: OFFER_REWARD_DELAY_MODAL,
					options: {
						...requiredOptions,
						...data,
					},
				});
				break;
			case 'offerwall_transaction_completed':
				modalStorage.pushModal({
					name: OFFER_REWARD_COMPLETE_MODAL,
					options: {
						...requiredOptions,
						...data,
					},
				});
				break;
			default:
				break;
		}
	};

	watch(
		[userAuthToken, notificationsList],
		async (newValue) => {
			const [token, notifications] = newValue;
			if (token && notifications?.length) {
				notifications.forEach((notification) => {
					showNotificationsModal(notification);
				});
			}
		},
		{ immediate: true }
	);
};
