<script lang="ts" setup>
import type { Modal } from '@/types';
import { type Component, onBeforeUnmount } from 'vue';
import { watch, ref, onMounted } from 'vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useDocumentVisibility } from '@vueuse/core';
import {
	DELETE_ACCOUNT_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_TIMEZONE_MODAL,
	REWARDS_MODAL,
	REWARD_SENT_MODAL,
	NEW_PASSWORD_SENT,
	CHANGE_LANGUAGE_MODAL,
	SET_USER_PASSWORD_MODAL,
	MOBILE_APP_HERE_MODAL,
	APP_REVIEW_MODAL,
	CONFIRM_CLAIM_MODAL,
	COMPLETED_5_SURVEYS_MODAL,
	LOGOUT_MODAL,
	FIRST_SURVEY_COMPLETED_MODAL,
	HALF_PROGRESS_NOTIFICATION_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	HALF_SURVEYS_INFO_MODAL,
	ASK_TRACKING_PERMISSIONS_MODAL,
	REFERRALS_NOTIFICATION_MODAL,
	UPDATE_AVAILABLE_MODAL,
	OFFER_REWARD_DELAY_MODAL,
	OFFER_REWARD_COMPLETE_MODAL,
} from '@/constants/modals';
import ModalWrapper from '@/components/common/ModalWrapper.vue';
import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import MobileAppHereModal from '@/components/modals/MobileAppHereModal.vue';
import ConfirmClaimDataModal from '@/components/modals/ConfirmClaimDataModal.vue';
import AppReviewModal from '@/components/modals/AppReviewModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import Completed5SurveysModal from '@/components/modals/Completed5SurveysModal.vue';
import LogoutModal from '@/components/modals/LogoutModal.vue';
import { useMessagesStore } from '@/stores/messages';
import { MessageChannels } from '@/enums';
import FirstSurveyCompletedModal from '@/components/modals/FirstSurveyCompletedModal.vue';
import HalfProgressNotificationModal from '@/components/modals/notifications/HalfProgressNotificationModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import HalfSurveysInfoModal from '@/components/modals/HalfSurveysInfoModal.vue';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import ReferralsNotificationModal from '@/components/modals/notifications/ReferralsNotificationModal.vue';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';
import OfferRewardCompleteModal from '@/components/modals/OfferRewardCompleteModal.vue';
import OfferRewardDelayModal from '@/components/modals/OfferRewardDelayModal.vue';

const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[REWARDS_MODAL]: RewardsModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[MOBILE_APP_HERE_MODAL]: MobileAppHereModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmClaimDataModal,
	[APP_REVIEW_MODAL]: AppReviewModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[COMPLETED_5_SURVEYS_MODAL]: Completed5SurveysModal,
	[LOGOUT_MODAL]: LogoutModal,
	[FIRST_SURVEY_COMPLETED_MODAL]: FirstSurveyCompletedModal,
	[HALF_PROGRESS_NOTIFICATION_MODAL]: HalfProgressNotificationModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[HALF_SURVEYS_INFO_MODAL]: HalfSurveysInfoModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[REFERRALS_NOTIFICATION_MODAL]: ReferralsNotificationModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
	[OFFER_REWARD_DELAY_MODAL]: OfferRewardDelayModal,
	[OFFER_REWARD_COMPLETE_MODAL]: OfferRewardCompleteModal,
};

const messageStore = useMessagesStore();
const visibility = useDocumentVisibility();
const { shiftModal, storage, activeModal } = useModalStorage();
const modal = ref<Modal | undefined>(undefined);
const showModal = ref(false);
const timeout = ref<any>(null);
const handleCloseModal = async () => {
	if (visibility.value === 'visible') {
		try {
			if (modal.value?.options?.id && !modal.value?.options?.achievement_key) {
				switch (modal.value?.options?.channel) {
					case MessageChannels.WEBSOCKET:
					case MessageChannels.NOTIFICATION:
						await messageStore.readNotification(modal.value.options.id);
						break;
					case MessageChannels.ANNOUNCEMENT:
						await messageStore.readAnnouncement(modal.value.options.id);
						break;
					default:
						break;
				}
			}
		} finally {
			await shiftModal();
		}

	}
};

onMounted(async () => {
	if (
		visibility.value === 'visible' &&
		!Object.keys(activeModal.value).length &&
		storage.value.length
	) {
		await shiftModal();
	}
});

watch(
	() => storage.value,
	async (value) => {
		if (visibility.value === 'visible' && value.length && !modal.value) {
			await shiftModal();
		}
	}
);
watch(visibility, async (value) => {
	if (value === 'visible' && !modal.value && storage.value.length) {
		await shiftModal();
	}
});

watch(
	activeModal,
	(value) => {
		if (Object.keys(value).length) {
			modal.value = { ...value };
			showModal.value = Boolean(modal.value);
		} else {
			showModal.value = false;

			if (modal.value?.options?.position === 'bottom') {
				// Set timeout for transition duration on close
				timeout.value = setTimeout(() => {
					modal.value = undefined;
					clearTimeout(timeout.value);
				}, 550);
			} else {
				modal.value = undefined;
			}
		}
	},
	{ immediate: true }
);

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});
</script>

<template>
	<ModalWrapper
		:show="showModal"
		:use-default-close="modal?.options?.useDefaultClose ?? true"
		:position="modal?.options?.position"
		:data-test-id="modal?.name || ''"
		@close-modal="handleCloseModal"
	>
		<component
			:is="{ ...MODAL_MAP[modal.name] }"
			v-if="modal?.name"
			:open="showModal"
			v-bind="modal?.options ? { options: modal.options } : {}"
			@close-modal="handleCloseModal"
		/>
	</ModalWrapper>
</template>
