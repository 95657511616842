export const DELETE_ACCOUNT_MODAL = 'delete_account_modal';
export const CHANGE_EMAIL_MODAL = 'change_email_modal';
export const CHANGE_PASSWORD_MODAL = 'change_password_modal';
export const CHANGE_TIMEZONE_MODAL = 'change_timezone_modal';
export const REWARDS_MODAL = 'rewards_modal';
export const REWARD_SENT_MODAL = 'reward_sent_modal';
export const NEW_PASSWORD_SENT = 'new_password_sent';
export const CHANGE_LANGUAGE_MODAL = 'change_language_modal';
export const SET_USER_PASSWORD_MODAL = 'set_user_password_modal';
export const MOBILE_APP_HERE_MODAL = 'mobile_app_here_modal';
export const CONFIRM_CLAIM_DATA_MODAL = 'confirm_claim_data_modal';
export const APP_REVIEW_MODAL = 'app_review_modal';
export const CONFIRM_CLAIM_MODAL = 'confirm_claim_modal';
export const COMPLETED_5_SURVEYS_MODAL = 'completed_5_surveys_modal';
export const LOGOUT_MODAL = 'logout_modal';
export const FIRST_SURVEY_COMPLETED_MODAL = 'first_survey_completed_modal';
export const HALF_PROGRESS_NOTIFICATION_MODAL =
	'half_progress_notification_modal';
export const DYNAMIC_ANNOUNCEMENT_MODAL = 'dynamic_announcement_modal';
export const HALF_SURVEYS_INFO_MODAL = 'half_surveys_info_modal';
export const REFERRALS_NOTIFICATION_MODAL = 'referrals_notification_modal';
export const ASK_TRACKING_PERMISSIONS_MODAL = 'ask_tracking_permissions_modal';
export const UPDATE_AVAILABLE_MODAL = 'update_available_modal';
export const OFFER_REWARD_DELAY_MODAL = 'offer_reward_modal';
export const OFFER_REWARD_COMPLETE_MODAL = 'offer_reward_complete_modal';
