<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { fetchTotalRewardsStats } from '@/api';
import type { TotalClaims } from '@/types';
import {
	PrimeLoaderSpinner,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { transformCurrency } from '@/utils/helpers';
import {TOTAL_CLAIMS, TOTAL_PENDINGS_TEXT, TOTAL_PENDINGS_TITLE} from '@/constants/locales';
import {storeToRefs} from "pinia";
import {useUserStore} from "@/stores/user";

const { totalPendingPayouts, currency } = storeToRefs(useUserStore());

const amountOfTotalPendings = computed(() => {
	return transformCurrency(
		+totalPendingPayouts.value! || 0,
		currency.value || 'USD',
		2
	);
});
</script>

<template>
	<div class="total-pendings__container">
		<div class="total-pendings__content">
			<PrimeText weight="500">{{ $t(TOTAL_PENDINGS_TITLE) }}</PrimeText>
			<PrimeText size="sm">
				{{ $t(TOTAL_PENDINGS_TEXT) }}
			</PrimeText>
		</div>
		<PrimeText weight="600">
			{{ amountOfTotalPendings }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
.total-pendings__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--p-grey-250);
	padding: 0.75rem 0.8125rem;
	box-shadow: var(--5s-shadow-card);
	border-radius: var(--p-radius-lg);
	margin-bottom: 0.75rem;
}

.total-pendings__content {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
</style>
