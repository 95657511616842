import { useAuthStore } from '@/stores/auth';
import { AUTH_ROUTE_NAME, USER_ROUTE_NAME } from '@/constants/routes';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { cidStorage } from '@/utils/local-storage';
import { useRewardsStore } from '@/stores/rewards';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { Platforms } from '@/enums';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';

export const checkUserEmailHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const forgotPasswordHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail && !authStore.resetPasswordSent) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSignUpCompleteHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail && !authStore.registrationEmailSent) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkExistingSocialsHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.socials.length) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSocialAuthHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.userSocialAuthData) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkResetPasswordHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	if (!to.query.email && !to.query.token) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkContextIdHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { platform } = useMobileApp();
	const cid = to.query.cid;
	if (platform.value === Platforms.WEB_MOBILE) {
		const { authToken } = useAppCookies();
		to.query = {
			...to.query,
			token: authToken,
		};
	}
	if (cid) {
		cidStorage.value = cid.toString();
		delete to.query.cid;
		delete to.query.tx_id;
		next({ path: to.path, query: to.query });
	} else {
		next();
	}
};

export const checkHistoryTypeHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const types: Array<string> = ['rewards', 'transactions'];
	const type = String(to.params.type || '');

	if (!types.includes(type)) {
		next({
			name: USER_ROUTE_NAME.REWARD_HISTORY,
			params: { type: 'rewards' },
			replace: true,
		});
	} else {
		next();
	}
};

export const checkInitializedClaim = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { currencyBalance } = storeToRefs(useUserStore());
	const rewardsStore = useRewardsStore();
	const { currentSelectedReward } = storeToRefs(rewardsStore);

	if (
		!currentSelectedReward.value ||
		currentSelectedReward.value?.money_value! > +currencyBalance.value
	) {
		rewardsStore.setSelectedReward(null);
		next({ name: USER_ROUTE_NAME.SURVEYS });
	} else {
		next();
	}
};

export const showAdjoeOfferwall = async (
	_to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { isAdjoeEnabled } = storeToRefs(useAppStore());
	const { isOfferwallsAvailable } = storeToRefs(useUserStore());

	if (!isAdjoeEnabled.value || !isOfferwallsAvailable.value) return false;

	const { isAndroid, isTablet } = useMobileApp();
	if (isAndroid.value && !isTablet.value) {
		const appStore = useAppStore();
		await appStore.showAdjoeOfferwall();
		return false;
	} else {
		next();
	}
};
