import { useI18n } from 'vue-i18n';
import type { ComputedRef } from 'vue';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import type {
	ProfileStats,
	ProfileSettingsItem,
	ProfileStatsItem,
} from '@/types';
import {
	PiCheckCircleFill,
	PiTrophyFill,
	PiCaretRight,
	PiEnvelopeSimple,
	PiClock,
	PiLock,
	PiAt,
	PiTranslate,
	PiTrash,
	PiSignout,
	PiWallet, PiUsersGroupAdd,
} from '@primeinsightsgroupllc/prime-icons';
import { transformCurrency } from '@/utils/helpers';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { getProfile } from '@/api';
import {
	PROFILE_SURVEYS_COMPLETED,
	CASH_EARNED,
	CHANGE_EMAIL,
	TIMEZONE,
	CHANGE_PASSWORD,
	EMAIL_PREFERENCES,
	DELETE_ACCOUNT,
	LANGUAGE,
	LOGOUT,
	TRANSACTIONS_REWARDS, REFERRALS,
} from '@/constants/locales';
import {
	CHANGE_EMAIL_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_LANGUAGE_MODAL,
	DELETE_ACCOUNT_MODAL,
	LOGOUT_MODAL,
} from '@/constants/modals';
import {
	TEST_ACCOUNT_CHANGE_EMAIL,
	TEST_ACCOUNT_TIMEZONE,
	TEST_ACCOUNT_CHANGE_PASSWORD,
	TEST_ACCOUNT_REWARD_HISTORY,
	TEST_ACCOUNT_EMAIL_PREFERENCES,
	TEST_ACCOUNT_LANGUAGE,
	TEST_ACCOUNT_DELETE,
	TEST_ACCOUNT_LOGOUT, TEST_ACCOUNT_REFERRALS,
} from '@/constants/dataTestAttrs';
import {USER_ROUTE_NAME} from "@/constants/routes";

export const useProfileView = (): {
	isProfileStatsLoaded: ComputedRef<boolean>;
	profileStatistic: ComputedRef<ProfileStatsItem[]>;
	settingsConfig: ComputedRef<ProfileSettingsItem[]>;
	moreSettingsConfig: ComputedRef<ProfileSettingsItem[]>;
} => {
	const router = useRouter();
	const { t } = useI18n();

	const { userLocales } = storeToRefs(useUserStore());

	const profileStats = ref<ProfileStats | null>(null);

	onBeforeMount(async () => {
		const data = await getProfile();
		if (data) {
			profileStats.value = data?.stats;
		}
	});

	const isProfileStatsLoaded = computed(() => !!profileStats.value);

	const profileStatistic = computed<ProfileStatsItem[]>(() => [
		{
			icon: PiCheckCircleFill,
			iconColor: 'base-primary',
			value: String(profileStats.value?.surveys?.completed),
			title: t(PROFILE_SURVEYS_COMPLETED),
		},
		{
			icon: PiTrophyFill,
			iconColor: 'text-default',
			value: `${transformCurrency(
				profileStats.value?.cash.total || 0,
				profileStats.value?.cash.currency,
				2
			)}`,
			title: t(CASH_EARNED),
		},
	]);

	const settingsConfig = computed<ProfileSettingsItem[]>(() => [
		{
			visible: true,
			icon: PiEnvelopeSimple,
			iconEnd: PiCaretRight,
			title: t(CHANGE_EMAIL),
			test: TEST_ACCOUNT_CHANGE_EMAIL,
			modal: { name: CHANGE_EMAIL_MODAL },
		},
		{
			visible: true,
			icon: PiClock,
			iconEnd: PiCaretRight,
			title: t(TIMEZONE),
			test: TEST_ACCOUNT_TIMEZONE,
			modal: { name: CHANGE_TIMEZONE_MODAL },
		},
		{
			visible: true,
			icon: PiLock,
			iconEnd: PiCaretRight,
			title: t(CHANGE_PASSWORD),
			test: TEST_ACCOUNT_CHANGE_PASSWORD,
			modal: { name: CHANGE_PASSWORD_MODAL },
		},
	]);

	const moreSettingsConfig = computed<ProfileSettingsItem[]>(() => [
		{
			visible: true,
			icon: PiUsersGroupAdd,
			iconEnd: PiCaretRight,
			title: t(REFERRALS),
			test: TEST_ACCOUNT_REFERRALS,
			handler: () =>
				router.push({
					name: USER_ROUTE_NAME.REFERRALS,
				}),
		},
		{
			visible: true,
			icon: PiWallet,
			iconEnd: PiCaretRight,
			title: t(TRANSACTIONS_REWARDS),
			test: TEST_ACCOUNT_REWARD_HISTORY,
			handler: () =>
				router.push({
					name: USER_ROUTE_NAME.REWARD_HISTORY,
					params: { type: 'transactions' },
				}),
		},
		{
			visible: true,
			icon: PiAt,
			iconEnd: PiCaretRight,
			title: t(EMAIL_PREFERENCES),
			test: TEST_ACCOUNT_EMAIL_PREFERENCES,
			handler: () => router.push({ name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS }),
		},
		{
			visible: userLocales.value?.length > 1,
			icon: PiTranslate,
			iconEnd: PiCaretRight,
			title: t(LANGUAGE),
			test: TEST_ACCOUNT_LANGUAGE,
			modal: { name: CHANGE_LANGUAGE_MODAL },
		},
		{
			visible: true,
			icon: PiTrash,
			iconEnd: PiCaretRight,
			title: t(DELETE_ACCOUNT),
			test: TEST_ACCOUNT_DELETE,
			modal: { name: DELETE_ACCOUNT_MODAL },
		},
		{
			visible: true,
			icon: PiSignout,
			iconEnd: PiCaretRight,
			title: t(LOGOUT),
			test: TEST_ACCOUNT_LOGOUT,
			modal: { name: LOGOUT_MODAL },
		},
	]);

	return {
		isProfileStatsLoaded,
		profileStatistic,
		settingsConfig,
		moreSettingsConfig,
	};
};
